/* ++++++++++++++++++++++++++++++++++++++++++++
//
// 基本設定など
//
++++++++++++++++++++++++++++++++++++++++++++ */

let gns = {
  // global name space
  siteType: "responsive", // pc or responsive
  ua: navigator.userAgent.toLowerCase(),
  // PC表示用 viewport サイズ
  contWidth: 1160,
  // デバイス判定
  deviceType: null,
  deviceOs: null,
  deviceOsVer: null,
  // ブラウザ判定
  browser: null,
  browserVer: null,
  // Googlebots判定
  googlebot: false,
  // タッチデバイス判定 modernizr で判断?
  isTouch: Modernizr.touchevents,
  // ピンチズーム されてるか
  pinchZoom: false,
  // マウスイベントハンドラー名変換
  overstart: null,
  outend: null,
  clicktouch: null,
  // DOMオブジェ
  win: null,
  html: null,
  body: null,
  htmlBody: null,
  header: null,
  ptopNav: null,
  // 表示のブレークポイント
  tgtSwichWidth: 801,
  tgtSwichWidth_2: 541,
  // XS:480 S:〜767 M:768〜 L
  winSize: null,
  // meta viewport
  metaViewport: null,
  metaViewportContentTemp: null,
  metaUserScalable: true,
  // 回転イベント 初期値は orientationchange 未サポートは resize
  orientationEvent: "orientationchange",
  orientationStatus: null,
  // 回転中かどうか
  duringRotation: false,
  // メニューフラグ
  menuFlag: false,
  // アンカースクロール時にヘッダー幅を考慮するか
  anchorOffsetFlag: false,
  // 現在のスクロールポジション
  currentScrollPos: 0
};

// デバイス
if (gns.ua.indexOf('iphone') != -1 || gns.ua.indexOf('ipad') != -1 || gns.ua.indexOf('ipod') != -1) {
  gns.deviceOs = "ios";
} else if (gns.ua.indexOf('android') != -1) {
  gns.deviceOs = 'android';
} else if (gns.ua.indexOf('macintosh') != -1) {
  gns.deviceOs = 'mac';
} else if (gns.ua.indexOf('windows') != -1) {
  gns.deviceOs = 'windows';
} else {
  gns.deviceOs = 'otherDevice';
}

// スマホ・タブレット判定
gns.deviceType = (function(u){
  return {
    tab:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1)
      || u.indexOf("ipad") != -1
      || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
      || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
      || u.indexOf("kindle") != -1
      || u.indexOf("silk") != -1
      || u.indexOf("playbook") != -1,
      sp:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
      || u.indexOf("iphone") != -1
      || u.indexOf("ipod") != -1
      || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
      || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
      || u.indexOf("blackberry") != -1
  }
})(gns.ua);

// デバイスに応じての回転イベント設定
if((gns.deviceType.tab || gns.deviceType.sp) && (gns.deviceOs != 'ios' || typeof window.onorientationchange === 'undefined')) {
  gns.orientationEvent = 'resize';
}

// ブラウザ
if (gns.ua.indexOf('msie') != -1 || gns.ua.indexOf('trident') != -1) {
  gns.browser = 'ie';
} else if (gns.ua.indexOf('safari') != -1 && gns.ua.indexOf('chrome') == -1 && gns.ua.indexOf('edge') == -1) {
  gns.browser = 'safari';
} else if (gns.ua.indexOf('safari') != -1 && gns.ua.indexOf('chrome') != -1 && gns.ua.indexOf('edge') == -1) {
  gns.browser = 'chrome';
} else if (gns.ua.indexOf('edge') != -1) {
  gns.browser = 'edge';
} else if (gns.ua.indexOf('firefox') != -1) {
  gns.browser = 'firefox';
}

// OS バージョン
gns.deviceOsVer = getOsVer(gns.deviceOs);
function getOsVer(os)
{
  if(os == 'otherDevice') {
    return false;
  }
  let s,l,v;
  if(os == 'android') {
    s = gns.ua.indexOf('android');
    l = gns.ua.slice(s).indexOf(';');
    v = gns.ua.slice(s, s+l);
    v = v.slice(v.indexOf(' '));
    return trimVer(v, 1);
  } else if(os == 'ios') {
    s = gns.ua.indexOf('os');
    l = gns.ua.slice(s).indexOf(' like')
    v = gns.ua.slice(s, s+l);
    v = v.slice(v.lastIndexOf(' ')).replace(/_/g, '.');
    return trimVer(v, 1);
  } else if(os == 'mac') {
    if(gns.browser == 'firefox') {
      s = gns.ua.indexOf('mac os');
      l = gns.ua.slice(s).indexOf(';')
      v = gns.ua.slice(s, s+l);
      v = v.slice(v.lastIndexOf(' '));
    } else {
      s = gns.ua.indexOf('mac os');
      l = gns.ua.slice(s).indexOf(')')
      v = gns.ua.slice(s, s+l);
      v = v.slice(v.lastIndexOf(' ')).replace(/_/g, '.');
    }
    return trimVer(v, 1);
  } else if(os == 'windows') {
    s = gns.ua.indexOf('windows');
    l = gns.ua.slice(s).indexOf(';')
    v = gns.ua.slice(s, s+l);
    v = v.slice(v.indexOf(' ')).replace(/_/g, '.');
    return trimVer(v, 1);
    // windowsは「nt 6.x」が代入される。
  }
}

// ブラウザ バージョン
gns.browserVer = getBrowserVer(gns.browser);
function getBrowserVer(browser)
{
  if(!browser) {
    return false;
  }
  let s,l,v;
  if (gns.browser == 'ie') {
    s = gns.ua.indexOf('msie');
    if(s != -1) {
      l = gns.ua.slice(s).indexOf(';');
      v = gns.ua.slice(s, s+l);
      v = v.slice(v.indexOf(' ')+1);
      return trimVer(v, 0);
    }
    s = gns.ua.indexOf('rv');//IE11
    if(s != -1) {
      l = gns.ua.slice(s).indexOf(')');
      v = gns.ua.slice(s, s+l);
      v = v.slice(v.indexOf(':')+1);
      return trimVer(v, 0);
    }
  } else if (gns.browser == 'safari') {
    s = gns.ua.indexOf('version/');
    if(s != -1) {
      l = gns.ua.slice(s).indexOf(' ');
      v = gns.ua.slice(s, s+l);
      v = v.slice(v.indexOf('/')+1);
      return trimVer(v, 0);
    }
  } else if (gns.browser == 'chrome') {
    s = gns.ua.indexOf('chrome/');
    if(s != -1) {
      l = gns.ua.slice(s).indexOf(' ');
      v = gns.ua.slice(s, s+l);
      v = v.slice(v.indexOf('/')+1);
      return trimVer(v, 0);
    }
  } else if (gns.browser == 'edge') {
    s = gns.ua.indexOf('edge/');
    if(s != -1) {
      v = gns.ua.slice(s);
      v = v.slice(v.indexOf('/')+1);
      return trimVer(v, 0);
    }
  } else if (gns.browser == 'firefox') {
    s = gns.ua.indexOf('firefox/');
    if(s != -1) {
      v = gns.ua.slice(s);
      v = v.slice(v.indexOf('/')+1);
      return trimVer(v, 0);
    }
  }
}

//タッチデバイス Modernizr.touchevents
if (!gns.isTouch) {
  gns.overstart = 'mouseenter';
  gns.outend = 'mouseleave';
  gns.clicktouch = 'mousedown';
} else {
  gns.overstart = 'touchstart';
  gns.outend = 'touchend';
  gns.clicktouch = 'touchstart';
}

// Googlebot 判定
// Google Page Speed 用
if (gns.ua.match(/google page speed insights|googlebot/)) {
  gns.googlebot = true;
}

// ウィンドウオブジェ
gns.win = jQuery(window);

// html
gns.html = jQuery('html');

// 表示のブレークポイント
setWinSize(); // ← iOS device-width が適応される前の 980 になる
gns.win.on('ready load resize', function() { // ← load 後は 320
  setWinSize();
});
function setWinSize(){
  if (gns.win.innerWidth() >= gns.tgtSwichWidth) {
    gns.winSize = 'm-l_size';
  } else if (gns.win.innerWidth() >= gns.tgtSwichWidth_2) {
    gns.winSize = 's_size';
  } else {
    gns.winSize = 'xs_size';
  }
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// メーラー
// eAdd2 + eAdd4 はダミー文字
++++++++++++++++++++++++++++++++++++++++++++ */
function sendEmail(eAdd1, eAdd2, eAdd3, eAdd4, eAdd5) {
  location.href = "mailto:" + eAdd1 + '@' + eAdd3 + eAdd5;
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// 拡大率取得
// 以下を使わなくても、イベント内で e.originalEvent.scale でいいかも。
++++++++++++++++++++++++++++++++++++++++++++ */
function getZoomePacentage() {
  let aSize = window.innerWidth;
  let bSize = document.body.clientWidth;
  return bSize / aSize;
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// 初html読み込み時にViewPortをセットする
//
++++++++++++++++++++++++++++++++++++++++++++ */
function setViewport() {
  // PC対応のみの場合
  if (gns.siteType === 'pc') {
    document.write('<meta name="viewport" content="width=' + gns.contWidth + '">');
    return;
  }
  // ↓ レスポンシブ 及び 準レスポンシブ（sp-tab768_pc）対応の場合
  // デバイスの向きを取得
  let orient = getOrientation();
  // レスポンシブの場合
  if (gns.siteType === 'responsive') {
    document.write('<meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1">');
    setOrientCls(orient);
  }
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// <html>タグの portrait landscaape クラスを書き換え
//
++++++++++++++++++++++++++++++++++++++++++++ */
function setOrientCls(v){
  let p = 'landscape';
  let l = 'portrait';
  if(!v) return;
  if(v == p) {
    gns.html.addClass(v).removeClass(l);
  } else if(v == l) {
    gns.html.addClass(l).removeClass(p);
  } else {
    if(!(!gns.html.hasClass('landscape') && !gns.html.hasClass('portrait'))) {
      gns.html.toggleClass(l);
      gns.html.toggleClass(p);
    }
  }
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// デバイスの向きを取得
//
++++++++++++++++++++++++++++++++++++++++++++ */
function getOrientation(){
  let orient;
  if (typeof window.matchMedia == "function") {
    if (window.matchMedia('(orientation:portrait)').matches) {
      orient = 'portrait';
    } else {
      orient = 'landscape';
    }
    return orient;
  } else {
    return false;
  }
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// セレクトボックスでのリンク
//
++++++++++++++++++++++++++++++++++++++++++++ */
function selectLink(obj) {
  if(obj.value) location.href = obj.value;
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// バージョンの数字整える
//
++++++++++++++++++++++++++++++++++++++++++++ */
// v = 0 はドット以下無し、v = 1 はドットが2つあるものを1つに 0.0.0 → 0.0
function trimVer(v, i){
  i = i || 0;
  if(i == 0){
    v = v.slice(0, v.indexOf('.'));
  } else {
    if((v.match(/\./g)||[]).length > 1) {
      v = v.slice(0, v.lastIndexOf('.'));
    }
  }
  return v;
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// リンクの無効化関数
//
++++++++++++++++++++++++++++++++++++++++++++ */
function cancelEvent(e){
  e.preventDefault();
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// 文字列反転
//
++++++++++++++++++++++++++++++++++++++++++++ */
function reverseText(v) {
  return v.split("").reverse().join("");
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// 文字列から拡張子抽出
//
++++++++++++++++++++++++++++++++++++++++++++ */
function getExtension(v) {
  let str = reverseText(v).split('.')[0];
  let ext = reverseText(str);
  return ext;
}

/* ++++++++++++++++++++++++++++++++++++++++++++
//
// 拡張子が画像かどうかをチェック
//
++++++++++++++++++++++++++++++++++++++++++++ */
function checkImageUrl(v) {
  if(v.match(/\.png$|\.gif$|\.jpeg$|\.jpg$|\.bmp$/i)){
    let ext = getExtension(v);
    return ext;
  } else {
    return false;
  }
}